import React from 'react';
import { Header, HeaderOptionItem } from '@appkit4/react-components/header';
import { navigate } from 'gatsby';
import "./NavTopbar.scss";

const NavTopbarApp = () => {
  const onClickLogo = (event: React.MouseEvent<HTMLElement>) => {
    navigate('/welcome')
  }
  const onClickSupport = (event: React.MouseEvent<HTMLElement>) => {
    navigate('mailto:victor.ramayrat@pwc.com')
  }


  return (
    <div className="header-demo-wrapper">
        <Header
            type="solid"
            onClickLogo={onClickLogo}
            titleTemplate={() => "ProEdge"} 
            subTitleTemplate={() => "Risk & Cyber"}
            optionsTemplate={() => {
              return (
                  <>
                      <HeaderOptionItem iconName="home-outline" onClick={onClickLogo} label="Home"></HeaderOptionItem>
                      {/* <HeaderOptionItem iconName="help-question-outline" onClick={onClickSupport} label="Support"></HeaderOptionItem> */}
                  </>
              )
            }}
            >
        </Header>
    </div>
  );
}

export default NavTopbarApp;