exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cloud-security-architect-csa-explore-tsx": () => import("./../../../src/pages/cloud-security-architect/csa-explore.tsx" /* webpackChunkName: "component---src-pages-cloud-security-architect-csa-explore-tsx" */),
  "component---src-pages-cloud-security-architect-csa-robocaller-tsx": () => import("./../../../src/pages/cloud-security-architect/csa-robocaller.tsx" /* webpackChunkName: "component---src-pages-cloud-security-architect-csa-robocaller-tsx" */),
  "component---src-pages-cloud-security-architect-index-tsx": () => import("./../../../src/pages/cloud-security-architect/index.tsx" /* webpackChunkName: "component---src-pages-cloud-security-architect-index-tsx" */),
  "component---src-pages-compliance-testing-analyst-cta-apply-ai-tsx": () => import("./../../../src/pages/compliance-testing-analyst/cta-apply-ai.tsx" /* webpackChunkName: "component---src-pages-compliance-testing-analyst-cta-apply-ai-tsx" */),
  "component---src-pages-compliance-testing-analyst-cta-explore-tsx": () => import("./../../../src/pages/compliance-testing-analyst/cta-explore.tsx" /* webpackChunkName: "component---src-pages-compliance-testing-analyst-cta-explore-tsx" */),
  "component---src-pages-compliance-testing-analyst-index-tsx": () => import("./../../../src/pages/compliance-testing-analyst/index.tsx" /* webpackChunkName: "component---src-pages-compliance-testing-analyst-index-tsx" */),
  "component---src-pages-cyber-defense-incident-responder-cdir-explore-tsx": () => import("./../../../src/pages/cyber-defense-incident-responder/cdir-explore.tsx" /* webpackChunkName: "component---src-pages-cyber-defense-incident-responder-cdir-explore-tsx" */),
  "component---src-pages-cyber-defense-incident-responder-cdir-incident-response-tsx": () => import("./../../../src/pages/cyber-defense-incident-responder/cdir-incident-response.tsx" /* webpackChunkName: "component---src-pages-cyber-defense-incident-responder-cdir-incident-response-tsx" */),
  "component---src-pages-cyber-defense-incident-responder-index-tsx": () => import("./../../../src/pages/cyber-defense-incident-responder/index.tsx" /* webpackChunkName: "component---src-pages-cyber-defense-incident-responder-index-tsx" */),
  "component---src-pages-cyber-jsx": () => import("./../../../src/pages/cyber.jsx" /* webpackChunkName: "component---src-pages-cyber-jsx" */),
  "component---src-pages-data-security-analyst-dsa-explore-tsx": () => import("./../../../src/pages/data-security-analyst/dsa-explore.tsx" /* webpackChunkName: "component---src-pages-data-security-analyst-dsa-explore-tsx" */),
  "component---src-pages-data-security-analyst-dsa-info-governance-tsx": () => import("./../../../src/pages/data-security-analyst/dsa-info-governance.tsx" /* webpackChunkName: "component---src-pages-data-security-analyst-dsa-info-governance-tsx" */),
  "component---src-pages-data-security-analyst-index-tsx": () => import("./../../../src/pages/data-security-analyst/index.tsx" /* webpackChunkName: "component---src-pages-data-security-analyst-index-tsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-internal-auditor-ia-explore-tsx": () => import("./../../../src/pages/internal-auditor/ia-explore.tsx" /* webpackChunkName: "component---src-pages-internal-auditor-ia-explore-tsx" */),
  "component---src-pages-internal-auditor-ia-powerbi-tsx": () => import("./../../../src/pages/internal-auditor/ia-powerbi.tsx" /* webpackChunkName: "component---src-pages-internal-auditor-ia-powerbi-tsx" */),
  "component---src-pages-internal-auditor-index-tsx": () => import("./../../../src/pages/internal-auditor/index.tsx" /* webpackChunkName: "component---src-pages-internal-auditor-index-tsx" */),
  "component---src-pages-risk-analyst-index-tsx": () => import("./../../../src/pages/risk-analyst/index.tsx" /* webpackChunkName: "component---src-pages-risk-analyst-index-tsx" */),
  "component---src-pages-risk-analyst-ra-develop-risk-management-tsx": () => import("./../../../src/pages/risk-analyst/ra-develop-risk-management.tsx" /* webpackChunkName: "component---src-pages-risk-analyst-ra-develop-risk-management-tsx" */),
  "component---src-pages-risk-analyst-ra-explore-tsx": () => import("./../../../src/pages/risk-analyst/ra-explore.tsx" /* webpackChunkName: "component---src-pages-risk-analyst-ra-explore-tsx" */),
  "component---src-pages-risk-jsx": () => import("./../../../src/pages/risk.jsx" /* webpackChunkName: "component---src-pages-risk-jsx" */),
  "component---src-pages-welcome-jsx": () => import("./../../../src/pages/welcome.jsx" /* webpackChunkName: "component---src-pages-welcome-jsx" */)
}

