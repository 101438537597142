import React from "react"
import "@appkit4/react-components/dist/styles/appkit4-react.min.css"
import "./Layout.scss"
import { Box, Grommet } from 'grommet';
import NavTopbarApp from './NavTopBar';
import type { PageProps } from "gatsby"

export default function Layout({ children }: PageProps) {
  return (
    <div className="ap-layout-bg">
        <Grommet full>
          <Box
            direction="column"
            height="100vh"
            background={{
              color: '#ffb600',
              image: 'url(../img/pe_cyber_login_page_bg@2x.png)',
              repeat: 'no-repeat',
              size: 'cover',
              position: 'bottom right'
            }}
          >
            <NavTopbarApp aria-label="Top Navigation"  />
            <Box>
              {children}
            </Box>
          </Box>
      </Grommet>
    </div>
  )
}